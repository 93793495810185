export default class MeigaranewsrecentlService_lazy {
  getNewsInfo(params, code) {
      const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');

      let url = 'https://api.tradersr.com/api/meigaranewsrecentl_lazy?code=' + code + '&';

      console.log(url + queryParams);

      return fetch(url + queryParams).then(res => res.json())
  }
}